@import "projects/web/ui-kit/theme";

/**
 * Fix for balloon toolbar when editor used in fixed containers.
 */
.ck.ck-balloon-panel {
    z-index: 99999 !important;
}

/**
 * Used when editor is used with black background
 */
.editor-inverse-colors {
    .ck.ck-content.ck-editor__editable {
        * {
            color: #FFFFFF;
        }
    }
}

div.ck-content {
    height: 100%;
}

// this style for ck-editor field in meet > meeting-dock > notes.component
.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners {
    border-color: transparent !important;
    box-shadow: none !important;
    padding: 0;
    border: none !important;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;

    h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 18px;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    p {
        margin: 0;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.02em;

        &::before {
            color: #807A8E;
        }

        a {
            color: $white;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    a {
        cursor: pointer;
    }

    ol {
        display: block;
        list-style-type: decimal;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }

    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
    }

    /**
     * Restores ability to interact with embedded media
     */
    &.ck-read-only {
        figure.media.ck-widget .ck-media__wrapper {
            div {
                pointer-events: auto;
            }
        }
    }
}

// fix for agenda notes link color
.meeting-notes .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners p a {
    color: $color-primary-main;
}

// fix for 'Videos inserted into Agenda can't play'
.ck-editor__editable:not(.ck-read-only) .ck-widget_selected .ck-media__wrapper > :not(.ck-media__placeholder) {
    pointer-events: initial !important;
}

.meeting-dock app-meet-notes .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners p {
    margin: 0;
}

app-meet-notes .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners p {
    color: $color-grayscale-black;
}

app-meet-private-notes .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners p::before {
    color: $color-grayscale-dark-1;
}
