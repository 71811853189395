//________________________________________________
//_________________COLOR variables for Light Theme
//________________________________________________

$primary-color: #2B2733;
$color-dark-purple: #2B2733;
$color-primary-main: $color-dark-purple;
$color-primary-dark-1: #1D1C21;
$color-primary-dark-2: #25222C;
$color-primary-light-1: #35313F;
$color-primary-light-2: #44414D;
//______________
$gold: #DFC051;
$color-complementary-gold: $gold;
$color-complementary-gold-dark-1: #BD970F;
$color-complementary-gold-dark-2: #D5B74D;
$color-complementary-gold-light-1: #EBCD64;
$color-complementary-gold-light-2: #FDE07A;
//______________
$color-grayscale-black: #121114;
$color-grayscale-white: #ffffff;
//______________
$color-grayscale-dark-1: #807A8E;
$color-grayscale-dark-2: #9A95AC;
$color-grayscale-dark-3: #A59FB9;
$color-grayscale-dark-4: #B4AFC6;
$color-grayscale-dark: $color-grayscale-dark-1;

$color-grayscale-light-1: #CECADA;
$color-grayscale-light-2: #EDEAF2;
$color-grayscale-light-3: #F3F3F9;
$color-grayscale-light-4: #FBFBFD;

$color-grayscale-week-card: #F0F0F7;
//______________

//_______Alerts
$color-alert-error: #ED315E;
$color-alert-error-dark: #D32952;
$color-alert-error-light: #FF577F;

$color-warning: #FFB03A;
$color-warning-dark: #A68400;
$color-warning-light: #FFDC82;

$color-success: #16C888;
$color-success-dark: #0F9866;
$color-success-light: #52D7A7;

$color-info: #37B7FF;
$color-info-dark: #0A95E3;
$color-info-light: #7ED1FF;
//_______Alerts_END_____/

$color-modal-background: rgba(18, 17, 20, 0.5);

//_________________COLOR variables for Light Theme____END
//_______________________________________________________


//________________________________________________
//_________________COLOR variables for Dark Theme ( Conference )
//________________________________________________

$dark-color-primary: #DFC051;
$color-complementary-gold-main: $dark-color-primary;
$dark-color-primary-dark-1: #BD970F;
$dark-color-primary-dark-2: #CCAC3A;
$dark-color-primary-light-1: #EBCD64;
$dark-color-primary-light-2: #FDE07A;
//__________
$dark-color-grayscale-dark-1: #1A191D;
$dark-color-grayscale-dark-2: #252329;
$dark-color-grayscale-dark-3: #333137;
$dark-color-grayscale-dark-4: #3E3C42;

$dark-color-grayscale-light-1: #5A5860;
$dark-color-grayscale-light-2: #66646C;
$dark-color-grayscale-light-3: #8C8A90;
$dark-color-grayscale-light-4: #A7A7A8;

//_________________COLOR variables for Dark Theme____END
//_______________________________________________________


//________________________________________________
//_________________COLOR variables for Calendar
//________________________________________________

$calendar-color-1: #402F6F;
$calendar-color-2: #713FFF;
$calendar-color-3: #508BFF;
$calendar-color-4: #50CAFF;
$calendar-color-5: #FFE924;
$calendar-color-6: #EEBE72;
$calendar-color-7: #FF8058;
$calendar-color-8: #FF4773;

//_________________COLOR variables for Calendar____END
//____________________________________________________


//________________________________________________
//_________________Variables for Chrome Extension
//________________________________________________

$sidebar-width: 420px;

/**
  Superhuman
 */

$superhuman-black: #171818;

$superhuman-main: #AEB1DD;
$superhuman-dark-1: #363D45; /** Not defined in Figma as such */
$superhuman-dark-2: #9295C7; /** Not defined in Figma as such */

$superhuman-dark-3: #505358;

$superhuman-complementary-blue-dark-1: #72C0EA; /** Not defined in Figma as such */

$superhuman-light-1: #727579;
$superhuman-light-2: #9B9DA0;
$superhuman-light-3: #B4B6BB;

$superhuman-light-4: #474C53; /** Not defined in Figma as such */

//________________________________________________
//_________________Variables for Chrome Extension____END
//________________________________________________


//________________________________________________
//_________________COLOR variables for Cards Elevation
//________________________________________________

$elevation-card: 0 2px 3px rgba(43, 39, 51, 0.05);
$elevation-card-raised: 0 4px 12px rgba(43, 39, 51, 0.06);
$elevation-dropdown: 0 15px 25px rgba(43, 39, 51, 0.1);
$elevation-modal: 0 25px 50px rgba(43, 39, 51, 0.2);

//_________________COLOR variables for Cards Elevation____END
//____________________________________________________


//_________________border for Cards Elevation

$card-border: 1px solid $color-grayscale-light-3;
$dark-card-border: 1px solid $color-grayscale-dark-3;

//_________________border for Cards Elevation____END


//_________________animation for UI elements

$transition-short: all 200ms ease;
$transition-regular: all 300ms ease;
$transition-long: all 500ms ease;

//_________________animation for UI elements____END


$purple: #4631c9; // unused color in Design System
$black: #2a2a2a; // unused color in Design System
$white: #ffffff; // unused color in Design System
$blue: #4e83cb; // unused color in Design System

$color-theme-primary-dark-3: #3a2868; // unused color in Design System
$color-theme-primary-light: #9874ff; // unused color in Design System
$color-theme-primary-extra-light: #c5b0ff; // unused color in Design System
$color-purple: #515ea0; // unused color in Design System
$color-warning-orange: #eebe72; // unused color in Design System
$color-dark-primary-light-3: #e9e1ff; // unused color in Design System

$sidebar-purple: #11112e; // unused color in Design System
$bg-dark-purple: #1c1c4f; // unused color in Design System
$bg-light-grey: #f8f8fb; // unused color in Design System
$bg-bleed-purple: #f4f4fc; // unused color in Design System

$text-bleed-purple: #515ea0; // unused color in Design System
$text-white: #fafafe; // unused color in Design System
$text-gray-disabled: #bcc2cf; // unused color in Design System
$text-purple-active: #413395; // unused color in Design System

/*
 * New colors
 */
$undock-black: #131519; // unused color in Design System
$undock-button-grey: #2D3036; // unused color in Design System
$undock-dark-grey: #1a1d23; // unused color in Design System
$undock-light-grey: #9e9fa2; // unused color in Design System
$undock-yellow: #ffb03a; // unused color in Design System

$expired-opacity: 0.65;
