@import "projects/web/ui-kit/theme";
@import "projects/web/ui-kit/utils";

.ud-input {
    width: 100%;
    margin: 0;
    padding: 0 12px !important;
    outline: none;
    font-size: 15px;
    line-height: 38px;
    background: $white;
    border: 1px solid $color-grayscale-light-2 !important;
    box-sizing: border-box;
    border-radius: 8px;
    color: $color-grayscale-black !important;
    caret-color: $color-grayscale-black !important;
    transition: all .5s ease;

    &::placeholder {
        color: $color-grayscale-dark-2 !important;
    }

    &:focus {
        border: 1px solid $primary-color !important;
    }

    &[disabled], &:disabled, &[aria-disabled=true] {
        color: $color-grayscale-dark-1 !important;
        border-color: $color-grayscale-light-3 !important;
        background-color: $color-grayscale-light-3 !important;
    }

    &.mat-select {
        @include no-select;
        padding: 0 !important;

        & > .mat-select-trigger {
            padding: 0 12px;
            line-height: 40px;
            height: 100%;
        }
    }

    &.dark,
    &.dark:disabled,
    &.dark[disabled] {
        border: 1px solid $dark-color-grayscale-dark-3 !important;
        background-color: transparent !important;
        color: $dark-color-grayscale-light-4 !important;
        caret-color: $dark-color-grayscale-light-4 !important;

        &::placeholder {
            color: $dark-color-grayscale-light-2 !important;
        }

        &.ng-touched.ng-invalid {
            border-color: $color-alert-error !important;
        }
    }
}




.ud-input--search {
    padding-left: 50px;
    background: white no-repeat;
}

.ud-select {
    position: relative;
    transition: all .5s ease;

    select {
        width: 100%;
        min-width: 150px;
        margin: 0;
        padding: 0 16px;
        padding-right: 30px;
        outline: none;
        font-size: 15px;
        line-height: 44px;
        background: $white;
        border: 2px solid $color-grayscale-dark-2;
        box-sizing: border-box;
        border-radius: 10px;
        color: $color-grayscale-black;
        caret-color: $color-grayscale-black;
        transition: all .5s ease;


        // disabling arrow
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;

        &::placeholder {
            color: $color-grayscale-dark-3;
        }

        &:focus {
            border-color: $primary-color;
        }
    }


    &:after {
        content: '';
        color: transparent;
        pointer-events: none;
        display: inline-block;
        position: absolute;
        width: 24px;
        height: 24px;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        // hack to support SVG fallback
        background-image: url("select-arrow.png");
        background-image: url("select-arrow.svg"), none;
    }
}

.ud-input-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 20px;

    span {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: $color-grayscale-dark !important;
        margin-bottom: 8px;
    }
}
