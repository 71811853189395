$icon-time: "\e900";
$icon-edit: "\e902";
$icon-more-vert: "\e903";
$icon-close: "\e901";
$icon-drag: "\e904";

@font-face {
  font-family: 'ud-icons';

  font-style: normal;
  font-weight: normal;
  font-display: block;

  src: url("../assets/fonts/ud-icons/ud-icons.ttf");
  src: url("../assets/fonts/ud-icons/ud-icons.woff");
}

.ud-icon {
  font-family: 'ud-icons' !important;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'liga';
}

.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-more-vert {
  &:before {
    content: $icon-more-vert;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-drag {
  &:before {
    content: $icon-drag;
  }
}
.icon-time {
  &:before {
    content: $icon-time;
  }
}
