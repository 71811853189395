@use "sass:math";
@import "projects/web/ui-kit/utils";
@import "projects/web/ui-kit/theme";

$width: 380px;
$radius: $width * .016891;
$transition: .3s;
$font-size: $width * .056;

/*
  All a user has to do to change the calendar size is
  change the font-size on the container and everything
  magically resizes accordingly. Relative units ftw!
*/

// @TODO NEED TO MAKE ONE STYLE COMPONENT FOR DATEPICKER  (second is: date-picker.component.scss)

:host {
    position: unset !important;
}

.qs-datepicker-container {
    display: flex;
    flex-direction: column;
    width: $width;
    min-width: $width;
    max-width: $width;
    position: absolute;
    z-index: 50 !important;
    left: 50% !important;
    transform: translateX(-50%);
    padding: 20px 8px 8px !important;
    font-size: 12.8px;
    font-family: Inter, sans-serif !important;
    color: #807A8E;
    user-select: none;
    border-radius: 24px;
    box-shadow: 0 12px 19px rgba(30, 20, 57, 0.1);
    transition: visibility .3s, opacity .3s linear;
    visibility: visible;
    opacity: 1;
    overflow: hidden;
    background: #fff;

    * {
        box-sizing: border-box;
    }

    &.qs-centered {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &.qs-hidden {
        // display: none;
        visibility: hidden;
        opacity: 0;
        max-width: 0;
        min-width: 0;
        max-height: 0;
        min-height: 0;
        display: block !important;
    }

    .qs-datepicker {
        .qs-overlay {
            position: absolute;
            top: 0;
            left: 0;
            background: $primary-color;
            color: white;
            width: 100%;
            height: 100%;
            padding: .5em;
            z-index: 1;
            opacity: 1;
            transition: opacity $transition;
            display: flex;
            flex-direction: column;

            &.qs-hidden {
                opacity: 0;
                z-index: -1;
            }

            .qs-overlay-year { // Overlay year input element.
                border: none;
                background: transparent;
                border-bottom: 1px solid white;
                border-radius: 0;
                color: white;
                font-size: $font-size;
                padding: .25em 0;
                width: 80%;
                text-align: center;
                margin: 0 auto;
                display: block;

                // https://goo.gl/oUuGkG
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                }
            }

            .qs-close {
                padding: .5em;
                cursor: pointer;
                position: absolute;
                top: 0;
                right: 0;
            }

            .qs-submit {
                border: 1px solid white;
                border-radius: $radius;
                padding: .5em;
                margin: 0 auto auto;
                cursor: pointer;
                background: rgba(128, 128, 128, .4);

                &.qs-disabled {
                    color: gray;
                    border-color: gray;
                    cursor: not-allowed;
                }
            }

            .qs-overlay-month-container {
                display: flex;
                flex-wrap: wrap;
                flex-grow: 1;
            }

            .qs-overlay-month {
                display: flex;
                justify-content: center;
                align-items: center;
                width: calc(100% / 3);
                cursor: pointer;
                opacity: .5;
                transition: opacity math.div($transition, 2);

                &.active, &:hover {
                    opacity: 1;
                }
            }
        }

        .qs-controls {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: stretch;
            align-items: center;
            flex-grow: 1;
            flex-shrink: 0;
            transition: filter $transition;
            padding: 0 12px;
            font-size: 20px;
            line-height: 26px;
            color: $color-grayscale-black;
        }

        .qs-arrow {
            cursor: pointer;
            transition: background .15s;
            display: flex;
            width: 40px;
            height: 40px;
            left: 309px;
            top: 24px;
            background: #FFF;
            color: $color-grayscale-black;
            border: 1px solid $color-grayscale-light-1 !important;
            border-radius: 20px;
            margin: 0;

            &:hover {
                background-color: $color-grayscale-light-3 !important;
            }

            &:active {
                background-color: $color-grayscale-light-1 !important;
            }

            &:after {
                content: url("../../assets/icons/arrow-chevron.svg") !important;
                width: 38px;
                height: 38px;
                font-size: 34px !important;
                line-height: 38px !important;
                font-weight: bold;
                text-align: center;
            }

            &.qs-left {
                order: 1 !important;

                &:after {
                    transform: rotate(180deg);
                }
            }

            &.qs-right {
                order: 3;

                &:after {
                }
            }
        }

        .qs-month-year {
            transition: border .2s;
            border-bottom: 1px solid transparent;
            text-align: center;
            order: 2;
            flex: 1;

            &:focus,
            &:active:focus {
                outline: none;
            }
        }

        .qs-month {
            padding-right: .5ex;
        }

        .qs-year {
            padding-left: .5ex;
        }

        .qs-squares {
            /*display: flex;
            flex-wrap: wrap;*/
            justify-content: center;
            transition: filter $transition;
            margin-top: 10px;
            cursor: default;

            display: grid;
            grid-template-columns: repeat(7, 1fr);

        }

        .qs-square {
            width: 44px !important;
            height: 44px !important;
            font-weight: normal !important;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 4px !important;
            padding: 8px 0 6px;
            transition: background .1s;
            border-radius: 100px;

            color: $color-grayscale-black;
            font-style: normal;
            font-size: 16px;
            line-height: 22px;

            &:nth-child(7n) {
                margin-right: 0;
            }

            span {
                cursor: pointer;
                display: flex;
                height: 32px;
                width: 32px;
                border-radius: 16px;
                align-items: center;
                justify-content: center;
            }

            &.qs-current {
                background-color: transparent;
                color: $color-grayscale-black;
                box-shadow: inset 0 0 0 2px $primary-color;
                text-decoration: unset !important;
            }

            &.qs-active {
                color: #FFFFFF !important;
                text-decoration: unset !important;
                background: $color-grayscale-black !important;
            }

            &.qs-range-date-start {
                &:not(.qs-range-date-6) {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }

            &.qs-range-date-middle {
                background: lighten(#E4DFF2, 10%);

                &:not(.qs-range-date-0):not(.qs-range-date-6) {
                    border-radius: 0;
                }

                &.qs-range-date-0 {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

                &.qs-range-date-6 {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }

            &.qs-range-date-end {
                &:not(.qs-range-date-0) {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }

            &.qs-empty {
                cursor: default;
                background: transparent !important;
                border: none !important;
                box-shadow: none !important;
            }

            &.qs-disabled {
                color: $color-grayscale-light-1;

                span {
                    cursor: not-allowed;
                }
            }

            &.qs-day {
                height: 20px;
                margin: 10px 4px 12px;
                padding: 0;
                font-weight: normal;
                font-size: 12px;
                color: $color-grayscale-dark-3;
                cursor: default;
            }

            &.qs-num {
                border-radius: 50%;
            }

            &:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active) {
                cursor: pointer;

                &:hover {
                    background-color: $color-grayscale-light-3 !important;
                }
                &:active {
                    background-color: $color-grayscale-light-1 !important;
                }

                /*
                &:hover {
                    cursor: pointer;

                    span {
                        background: $color-grayscale-light-3;
                    }
                }
                */
            }
        }

        .qs-event {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                width: $width * .03;
                height: $width * .03;
                border-radius: 50%;
                background: #07f;
                bottom: 0;
                right: 0;
            }

            &.qs-disabled,
            &.qs-empty {
                &:after {
                    background: lighten(#07f, 40);
                }
            }
        }
    }
}

// MOBILE
@media all and (max-width: 400px) {
    .qs-datepicker-container {
        width: 100%;
        max-width: calc(100% - 10px);
        min-width: auto;

        /*
        min-width: 340px !important;
        max-width: 340px;
        margin-top: 20px;
        */

        .qs-datepicker .qs-square {
            /*
            width: 36px !important;
            height: 36px !important;
            margin-right: 10px;
             */
            width: auto !important;
        }
    }
}
