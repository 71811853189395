@import "../ui-kit/variables";

:root {
    --color-primary-main: #{$color-primary-main};
    --color-grayscale-dark: #{$color-grayscale-dark-2};
    --color-grayscale-white: #{$color-grayscale-white};
    --color-grayscale-black: #{$color-grayscale-black};
    --color-grayscale-dark-1: #{$color-grayscale-dark-1};
    --color-grayscale-dark-2: #{$color-grayscale-dark-2};
    --color-grayscale-dark-3: #{$color-grayscale-dark-3};
    --dark-color-primary: #{$dark-color-primary};
}
