@import "variables";

@mixin defaultFont {
    font-family: 'Inter', sans-serif;
}

@mixin bebasFont {
    font-family: 'Bebas Neue', sans-serif;
}

@mixin font10 {
    /* identical to box height, or 140% */
    font-size: 10px;
    line-height: 14px;
}

@mixin font11 {
    /* identical to box height, or 133% */
    font-size: 11px;
    line-height: 14px;
}

@mixin font12 {
    /* identical to box height, or 133% */
    font-size: 12px;
    line-height: 16px;
}

@mixin font13 {
    font-size: 13px;
    line-height: 18px;
}

@mixin font14 {
    /* identical to box height, or 143% */
    font-size: 14px;
    line-height: 20px;
}

@mixin font15 {
    /* identical to box height, or 147% */
    font-size: 15px;
    line-height: 22px;
}

@mixin font16 {
    /* identical to box height, or 138% */
    font-size: 16px;
    line-height: 22px;
}

@mixin font18 {
    /* identical to box height, or 144% */
    font-size: 18px;
    line-height: 26px;
}

@mixin font20 {
    /* identical to box height, or 130% */
    font-size: 20px;
    line-height: 26px;
}

@mixin font24 {
    /* identical to box height, or 125% */
    font-size: 24px;
    line-height: 30px;
}

@mixin font28 {
    /* identical to box height, or 129% */
    font-size: 28px;
    line-height: 36px;
}

@mixin font32 {
    font-size: 32px;
    line-height: 40px;
    /* identical to box height, or 125% */
}

@mixin font46 {
    font-size: 46px;
    line-height: 56px;
}

/* Header 34 Bebas Neue */
@mixin fontHeader34Bebas {
    @include bebasFont;
    @include font32;

    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.02em;
}

/* Header 46 Bebas Neue */
@mixin fontHeader46Bebas {
    @include bebasFont;
    @include font46;

    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.02em;
}

@mixin fontRegular {
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.02em;
}

@mixin fontMedium {
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.02em;
}

@mixin fontCaption {
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

/* Header 20 Regular */
@mixin fontHeader20Regular {
    @include defaultFont;
    @include fontRegular;
    @include font20;
}

/* Header 24 Regular */
@mixin fontHeader24Regular {
    @include defaultFont;
    @include fontRegular;
    @include font24;
}

/* Header 28 Regular */
@mixin fontHeader28Regular {
    @include defaultFont;
    @include fontRegular;
    @include font28;
}

/* Body 14 Regular */
@mixin fontBody14Regular {
    @include defaultFont;
    @include fontRegular;
    @include font14;
}

/* Body 14 Medium */
@mixin fontBody14Medium {
    @include fontBody14Regular;
    font-weight: 500;
}

/* Body 14 Semi-Bold */
@mixin fontBody14SemiBold {
    @include fontBody14Regular;
    font-weight: 600;
}

/* Body 15 Regular */
@mixin fontBody15Regular {
    @include defaultFont;
    @include fontRegular;
    @include font15;
    line-height: 20px;
}

/* Body 13 Medium */
@mixin fontBody13Medium {
    @include defaultFont;
    @include fontMedium;
    @include font13;
}

/* Body 15 Medium */
@mixin fontBody15Medium {
    @include defaultFont;
    @include fontMedium;
    @include font15;
}

/* Body 16 Medium */
@mixin fontBody16Medium {
    @include defaultFont;
    @include fontMedium;
    @include font16;
}

/* Body 16 Regular */
@mixin fontBody16Regular {
    @include defaultFont;
    @include fontRegular;
    @include font16;
}

/* Small Label 10 Medium */
@mixin label10SmallMedium {
    @include defaultFont;
    @include fontMedium;
    @include font10;
    letter-spacing: -0.01em;
}

/* Label 11 Regular */
@mixin label11Regular {
    @include defaultFont;
    @include fontRegular;
    @include font11;
}

/* Label 11 Medium */
@mixin label11Medium {
    @include defaultFont;
    @include fontMedium;
    @include font11;
}

/* Label 12 Regular */
@mixin label12Regular {
    @include defaultFont;
    @include fontRegular;
    @include font12;
}

/* Label 12 Medium */
@mixin label12Medium {
    @include defaultFont;
    @include fontMedium;
    @include font12;
}

/* Small Caption / Caption 10 */
@mixin smallCaption {
    @include defaultFont;
    @include font10;
    @include fontCaption;
}

/* Caption 10 */
@mixin fontCaption10 {
    @include defaultFont;
    @include font11;
    @include fontCaption;
    font-weight: 700;
    letter-spacing: 1px;
}

/* Caption 11 */
@mixin fontCaption11 {
    @include defaultFont;
    @include font11;
    @include fontCaption;
    font-weight: 600;
    letter-spacing: 1px;
}

/* Caption 12 */
@mixin fontCaption12 {
    @include defaultFont;
    @include font12;
    @include fontCaption;
    font-weight: 600;
    letter-spacing: 1px;
}

/* Caption 14 */
@mixin fontCaption14 {
    @include fontCaption12;
    @include font14;
}

@mixin label12RegularUppercase {
    @include label12Regular;
    font-weight: 600;
    text-transform: uppercase;
}
