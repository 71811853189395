@import "variables";
@import "typography";
@import "scrollbars";
@import "breakpoints";

@mixin textEllipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: calc(100% - 0.1px); // imitate relative 100% to prevent having fixed PX width
}

@mixin requiredLabel {
    &:after {
        content: " *";
        color: $color-alert-error;
    }
}

@mixin flexColumn {
    display: flex;
    flex-direction: column;
}

@mixin flexCenteredCol {
    @include flexColumn;
    align-items: center;
    justify-content: center;
}

@mixin fullSizeFlex {
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex-direction: column;
    overflow: hidden;
}

@mixin no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}



@mixin badge {
    @include label12RegularUppercase;

    display: inline-block;
    flex-direction: row;
    align-items: center;
    padding: 3px 8px;
    border-radius: 16px;
    vertical-align: middle;
}

@mixin greyBadge {
    @include badge;
    background: rgba(154, 149, 172, 0.1);
    color: $color-grayscale-dark-1;
}

@mixin goldBadge {
    @include badge;
    background: $color-complementary-gold-main;
    color: $color-grayscale-black;
}

@mixin newBadge {
    @include badge;
    background: rgba(223, 192, 81, 0.1);
    color: $color-complementary-gold-dark-1;
}

/* Card */
@mixin card($border-radius: 16px) {
    border: 1px solid $color-grayscale-light-3;
    border-radius: $border-radius;
    background-color: $color-grayscale-white;
    box-shadow: 0 2px 3px rgba(29, 26, 34, 0.03);
}

/* Tooltip */
@mixin tooltip {

    @include label12Medium;
    @include light-2-card-raised;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 8px;
    gap: 10px;
    width: 250px;
    max-width: 90vw;
    border-radius: 4px;

    background: $color-grayscale-white;
    color: $color-grayscale-black;
    opacity: 0.99;
}

@mixin tooltipDark {
    @include tooltip;
    @include dark-2-card-raised;

    background: $color-grayscale-black;
    color: $color-grayscale-white;
    opacity: 0.9;
}



/* Light/1. Card */
@mixin light-1-card {
    box-shadow: 0 2px 3px rgba(29, 26, 34, 0.03);
}

/* Light/2. Card Raised */
@mixin light-2-card-raised {
    box-shadow: $elevation-card-raised;
}

/* Dark/2. Card Raised */
@mixin dark-2-card-raised {
    box-shadow: 0 4px 12px rgba(13, 12, 15, 0.4);
}

/* Light/3. Dropdown */
@mixin light-3-dropdown {
    box-shadow: 0 15px 25px rgba(29, 26, 34, 0.1);
}

/* Sidebar/Light */
@mixin sidebar-light-shadow {
    box-shadow: 2px 0 3px rgba(29, 26, 34, 0.03), 1px 0 0 $color-grayscale-light-3;
}

/* Button Medium */
@mixin buttonMedium(
    $textColor: $color-primary-main,
    $borderColor: $color-grayscale-light-2,
    $backgroundColor: transparent,
    $hoverBackgroundColor: $color-grayscale-light-3,
) {
    @include defaultFont;
    @include fontMedium;
    @include font15;
    font-weight: 600;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 20px;
    margin: 0;
    border: 1px solid $borderColor;
    border-radius: 10px;
    color: $textColor;
    cursor: pointer;
    background-color: $backgroundColor;

    transition: $transition-short;

    &.no-border {
        border: none;
    }

    &.circle {
        $size: 40px;
        min-width: $size;
        max-width: $size;
        min-height: $size;
        max-height: $size;
        border-radius: $size;
    }

    &:hover{
        background-color: $hoverBackgroundColor;
    }

    &:active {
        background-color: $color-grayscale-light-1;
    }

    &.primary {
        background: $color-primary-main;
        color: $color-grayscale-white;
        border-color: $color-primary-main;

        &:hover{
            background-color: $color-primary-main;
        }

        &:active {
            background-color: $color-primary-main;
        }
    }

    &[disabled], &.mat-disabled{
        cursor: not-allowed;
    }
}

/* Button Small */
@mixin buttonSmall(
    $textColor: $color-primary-main,
    $borderColor: $color-grayscale-light-2,
    $backgroundColor: transparent,
    $hoverBackgroundColor: $color-grayscale-light-3,
) {
    @include buttonMedium(
        $textColor,
        $borderColor,
        $backgroundColor,
        $hoverBackgroundColor,
    );

    @include font14;

    border-radius: 8px;
    padding: 10px 12px;

    &.circle {
        padding: 0;
        $size: 24px;
        min-width: $size;
        max-width: $size;
        min-height: $size;
        max-height: $size;
        border-radius: $size;
    }
}

@mixin darkButtonMedium() {
    @include buttonMedium(
        $color-grayscale-white,
        $color-primary-main,
        $color-primary-main,
        $color-grayscale-black,
    )
}

@mixin lightButtonMedium() {
    @include buttonMedium(
        $color-primary-main,
        $color-grayscale-light-2,
        transparent,
    )
}

@mixin inputText {
    @include font15;

    border: none;
    outline: none;
    appearance: none;
    box-shadow: none;

    width: 100%;
    height: 100%;
    user-select: text;
    background: transparent;

    &::placeholder {
        color: $color-grayscale-dark-3;
        @include font15;
    }

    &:focus {
        border: none;
        outline: none !important;
        box-shadow: none !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}

@mixin avatar-24 {
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
}

@mixin avatar-40 {
    min-width: 40px;
    max-width: 40px;
    min-height: 40px;
    max-height: 40px;
}

@mixin avatar-48 {
    min-width: 48px;
    max-width: 48px;
    min-height: 48px;
    max-height: 48px;
}

@mixin avatar-57 {
    min-width: 57px;
    max-width: 57px;
    min-height: 57px;
    max-height: 57px;
}
