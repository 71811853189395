@import "variables";

@mixin no-scroll {
    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    &::-webkit-scrollbar {
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        display: none;
    }
}

@mixin purple-scrollbar {
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-corner {
        transition: opacity .3s linear;
    }

    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: $color-grayscale-dark-2;
        border-radius: 85px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 85px;
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }

    &:hover {
        &::-webkit-scrollbar-thumb {
            background: $color-grayscale-dark-2;
        }

        &::-webkit-scrollbar-track {
            opacity: 0.25;
        }
    }

    &:active {
        &::-webkit-scrollbar-thumb {
            opacity: 1;
            background: $color-grayscale-dark-2;
        }

        &::-webkit-scrollbar-track {
            opacity: 1;
        }
    }
}

@mixin gray-scrollbar {
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-corner {
        transition: opacity .3s linear;
    }

    &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
    }

    &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: $dark-color-grayscale-light-4;
        border-radius: 85px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 85px;
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }

    &:hover {
        &::-webkit-scrollbar-thumb {
            background: $dark-color-grayscale-light-4;
        }

        &::-webkit-scrollbar-track {
            opacity: 0.25;
        }
    }

    &:active {
        &::-webkit-scrollbar-thumb {
            opacity: 1;
            background: $color-grayscale-dark-2;
        }

        &::-webkit-scrollbar-track {
            opacity: 1;
        }
    }
}

    @mixin black-scrollbar {
        &::-webkit-scrollbar,
        &::-webkit-scrollbar-button,
        &::-webkit-scrollbar-thumb,
        &::-webkit-scrollbar-track,
        &::-webkit-scrollbar-corner {
            transition: opacity .3s linear;
        }
    
        &::-webkit-scrollbar {
            width: 2px;
            height: 2px;
        }
    
        &::-webkit-scrollbar-button {
            width: 0;
            height: 0;
        }
    
        &::-webkit-scrollbar-thumb {
            background: $color-grayscale-black;
            border-radius: 85px;
        }
    
        &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 85px;
        }
    
        &::-webkit-scrollbar-corner {
            background: transparent;
        }
    
        &:hover {
            &::-webkit-scrollbar-thumb {
                background: $color-grayscale-black;
            }
    
            &::-webkit-scrollbar-track {
                opacity: 0.25;
            }
        }
    
        &:active {
            &::-webkit-scrollbar-thumb {
                opacity: 1;
                background: $color-grayscale-black;
            }
    
            &::-webkit-scrollbar-track {
                opacity: 1;
            }
        }
    }
