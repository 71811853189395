@import "projects/web/ui-kit/theme";
@import "projects/web/ui-kit/utils";

.ud-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 800px;
    max-height: 48px;
    color: $white;
    background: $primary-color;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    line-height: 48px;
    border: none;
    cursor: pointer;
    outline: none;
}

.ud-flat-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 800px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 44px;
    text-align: center;
    color: $color-primary-main;
    background: $color-grayscale-white;
    border: 1px solid $color-grayscale-light-2;
    outline: none;
    border-radius: 10px;
    cursor: pointer;
    position: relative;

    &:before {
        display: block;
        position: absolute;
        z-index: -2;
        content: '';
        width: 100%;
        height: 42px;
        background: $color-grayscale-white;
        border-radius: 10px;
    }

    &:after {
        display: block;
        position: absolute;
        z-index: 0;
        content: '';
        width: 100%;
        height: 42px;
        background: transparent;
        border-radius: 10px;
        clip-path: circle(10%);
    }

    &:hover {
        transition: 0.3s;
        border: 1px solid $color-grayscale-light-1;

        &:before {
            transition: 0.3s;
            background-color: #612DF2;
        }
    }

    &:active {
        &:after {
            animation: circle 0.15s linear;
            background-color: rgba(255, 255, 255, 0.15);
        }
    }

    span {
        user-select: none;
        line-height: 42px;
        margin: 0;
        position: relative;
        z-index: 2;
        color: $color-primary-main;
    }

    @keyframes circle {
        0% {
            clip-path: circle(0%);
        }
        100% {
            clip-path: circle(100%);
        }
    }
}
