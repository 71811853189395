@import '../ui-kit/theme';

.ud-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 860px;
    height: auto;
    margin: 0 auto 32px auto;
    padding: 20px 12px;
    background: #FFFFFF;
    border-radius: 16px;
    box-shadow: $elevation-card;
}

.ud-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: unset;
    }

    &.two-col,
    &.three-col {
        align-items: flex-start;
        justify-content: space-between;
    }

    &.two-col {
        .ud-row-item {
            max-width: calc(50% - 10px);

        }
    }

    &.three-col {
        .ud-row-item {
            max-width: calc(33% - 10px);
        }
    }

    &.list-header {
        padding: 8px 0;
        margin-left: 12px;
        margin-bottom: 8px;

        .ud-row-item {
            max-width: 20%;

            &:first-child {
                max-width: 25%;
                min-width: 200px;
            }

            * {
                margin-bottom: 0;
                font-size: 12px;
            }
        }
    }

    .ud-row-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 4;
        width: 100%;
        max-width: 100%;
    }
}

.ud-separator {
    width: 100%;
    margin: 0 0 14px 0;
    border: none;
    border-top: 1px solid #EDEAF2;
    height: 1px;
}

.ud-errors-box {
    width: 100%;

    .ud-error-message {
        color: $color-alert-error;
        margin: 3px 0;
        font-size: 12px;
    }

    .ud-warning-message {
        color: $color-complementary-gold-dark-2;
        margin: 3px 0;
        font-size: 12px;
    }
}

.ud-form-input {
    width: 100%;
    display: flex;
    align-items: center;
    @include defaultFont;
    border: 1px solid $color-grayscale-light-2;
    background-color: $color-grayscale-white;
    border-radius: 8px;
    padding-right: 12px;
    transition: $transition-regular;
    height: 40px;
    overflow: hidden;

    .cancel-button {
        cursor: pointer;
        border-radius: 50%;
        margin-left: 12px;
        transition: $transition-regular;

        &:hover  {
            background-color: $color-grayscale-light-3;
        }
    }

    .content-container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    textarea {
        border: none;
        outline: none;
        padding: 12px 14px;
    }

    input {
        padding: 8px 0 8px 12px;
        @include fontBody15Regular;
        width: 100%;
        border: none;
        outline: none;
    }

    &.dark {
        border: 1px solid $dark-color-grayscale-dark-3;
        background-color: transparent;

        &:focus-within {
            border: 1px solid $dark-color-grayscale-dark-3;
        }

        textarea {
            background-color: transparent;
            color: $dark-color-grayscale-light-4 ;
            caret-color: $dark-color-grayscale-light-4;

            &::placeholder {
                color: $dark-color-grayscale-light-2;
            }
        }

        input {
            background-color: transparent;
            color: $dark-color-grayscale-light-4;
            caret-color: $dark-color-grayscale-light-4;
        }
    }

    &.with-border {
        .content-container {
            border-right: 1px solid #EDEAF2;
        }

        input {
            padding: 8px 0 8px 12px;
        }
    }

    &.with-icon {
        .content-container {
            min-width: 40px;
            background-color: #FFFFFF;
        }

        &:not(&.with-border) {
            input {
                padding-left: 2px;
            }
        }
    }

    &.invalid,
    &:has(>.ng-invalid.ng-touched) {
        border-color: $color-alert-error;
    }

    &.with-text {
        @include fontBody15Regular;
        color: $color-grayscale-dark-1;

        .content-container {
            padding: 8px 12px;
            background-color: $color-grayscale-light-4;
        }
    }

    &:focus-within {
        border-color: black;

        &.invalid,
        &:has(>.ng-invalid.ng-touched) {
            border-color: $color-alert-error;
        }
    }
}

.ud-textarea {
    width: 100%;
    overflow: hidden;
    height: 48px;
    resize: none;
    outline: none;
    border: 1px solid #EDEAF2;
    border-radius: 8px;
    padding: 14px 16px;
    transition: $transition-regular;
    font-size: 15px;
    font-family: "Inter", sans-serif;
    letter-spacing: -0.02em;

    &:focus,
    &:focus-within {
        border-color: black;
    }

    &.ng-invalid.ng-touched {
        border-color: red;
    }
}

.ud-form-label {
    @include fontCaption11;
    color: $color-grayscale-dark;
    margin-bottom: 12px;
}

.ud-form-header {
    @include fontHeader20Regular;
    letter-spacing: -0.4px;
    color: $color-grayscale-black;
    margin-bottom: 16px;
}

.ud-vertical-menu {
    width: 100%;
    max-width: 270px;
    margin-right: 32px;
    position: sticky;

    .title-row {
        margin-bottom: 24px;
    }

    .page-title {
        @include fontHeader24Regular;
        color: $color-grayscale-black;
    }

    .options-routes {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;

        @include purple-scrollbar;

        hr {
            width: 100%;
            height: 1px;
            margin: 8px 0;
            background-color: $color-grayscale-light-2;
            border: none;
        }
    }

    .route-link {

        --icon-color: #{$color-grayscale-dark-1};

        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-bottom: 4px;
        padding: 8px;
        background: transparent;
        border-radius: 8px;
        outline: none;
        text-decoration: none;
        border: none;
        cursor: pointer;

        &.-mobile {
            display: none;
        }

        &:hover {
            background: $color-grayscale-light-2;
        }

        svg {
            width: 24px;
            height: 24px;
        }

        span {
            width: 100%;
            margin-left: 16px;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            letter-spacing: -0.02em;
            color: $color-grayscale-dark;
        }

        .tab-name {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .new {
                max-width: 44px;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                letter-spacing: -0.02em;
                color: $color-complementary-gold-dark-1;
                padding: 4px 8px;
                background: rgba($dark-color-primary, 0.1);
                border-radius: 16px;
                margin-left: 12px;
            }
        }
    }

    .-link-active {
        background: $color-grayscale-light-2;
        --icon-color: #{$primary-color};

        span {
            color: $primary-color;
        }

        &.-revert-colors {
            path, svg g rect {
                fill: none;
                stroke: $primary-color;
            }
        }
    }
}

.ud-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    letter-spacing: -0.02em;
    width: fit-content;
    cursor: pointer;
    border: none;
    transition: $transition-short;

    &:disabled {
        opacity: 0.5;

        &.mat-button {
            color: unset;
        }
    }

    &.ud-btn-transparent {
        background-color: transparent;
        border: 1px solid $dark-color-grayscale-dark-3;
        color: $color-grayscale-white;
    }

    &.ud-btn-primary {
        color: $color-grayscale-white;
        background-color: $color-primary-main;

        &:not(&:disabled) {
            &:hover {
                background: $color-primary-light-2;
            }
        }

        &:active {
            background: $color-primary-light-2;
        }

        &:disabled {
            &.mat-button {
                color: $color-grayscale-white;
            }
        }
    }

    &.btn-dark-primary {
        color: $color-grayscale-black;
        background-color: $dark-color-primary;

        &:not(&:disabled) {
            &:hover {
                background: $dark-color-primary-light-1;
            }
        }

        &:active {
            background: $dark-color-primary-light-2;
        }

        &:disabled {

        }
    }

    &.ud-btn-secondary {
        color: $color-primary-main;
        background-color: $color-grayscale-white;
        border: 1px solid $color-grayscale-light-2;

        &.mat-button {
            border: 1px solid $color-grayscale-light-2;
        }

        &:active {
            background: $color-grayscale-light-1;
        }

        &:not(&:disabled) {
            &:hover {
                background: $color-grayscale-light-3;
            }
        }

        &:disabled {

        }
    }

    // sizes
    &.btn-large { // h:48px
        @include defaultFont;
        @include font15;
        padding: 13px 15px;
        border-radius: 10px;
    }

    &.btn-medium { // h:40px
        @include defaultFont;
        @include font14;
        padding: 10px 12px;
        border-radius: 8px;
    }

    &.btn-small { // h:32px
        @include defaultFont;
        @include font14;
        padding: 6px 8px;
        border-radius: 6px;
    }
}

.ud-counter {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    //z-index: 1;
    width: 20px;
    height: 20px;
    background: $color-complementary-gold;
    color: $color-primary-main;
    font-family: Inter, sans-serif;
    font-weight: bold;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.01em;
    border: 1px solid $color-grayscale-white;
    border-radius: 10px;
}

// Avatars
.ud-avatar {
    overflow: hidden;
    border-radius: 50%;
    background-color: $color-grayscale-light-1;

    &:has(img)  {
        background-color: transparent;
    }

    img {
        width: 100%;
        height: 100%;
    }

    &.avatar-24 {
        @include avatar-24;
    }

    &.avatar-40 {
        @include avatar-40;
    }

    &.avatar-48 {
        @include avatar-48;
    }

    &.avatar-57 {
        @include avatar-57;
    }
}

.ud-badge {
    &.badge-gray {
        @include greyBadge;
    }

    &.badge-gold {
        @include goldBadge;
    }

    &.badge-sm {
        @include smallCaption;
    }
}
